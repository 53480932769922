import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import remark from 'remark'
import remarkHtml from 'remark-html'
import recommended from 'remark-preset-lint-recommended'
import Content, { HTMLContent } from '../components/Content'
import Layout from '../components/layout'
import { getImage } from '../util'

const PageTemplate = ({
  content,
  contentComponent,
  helmet,
  title,
  featuredImage,
}) => {
  const PageContent = contentComponent || Content

  return (
    <section className="section grid-container pre-footer-padding">
      {helmet || ''}
      <div className="grid-x grid-margin-x content">
        <div className="cell small-12 medium-8 medium-offset-2">
          {getImage(featuredImage, title)}
          <h1 className="page-header">
            {title}
          </h1>
          <PageContent content={content} />
        </div>
      </div>
    </section>
  )
}

PageTemplate.propTypes = {
  content: PropTypes.string.isRequired,
  contentComponent: PropTypes.func,
  helmet: PropTypes.object,
  title: PropTypes.string,
  publishedOn: PropTypes.string,
  featuredImage: PropTypes.object,
  author: PropTypes.object,
}

const Page = ({ data }) => {
  const page = data.page
  const edition = data.edition

  const content = remark()
    .use(recommended)
    .use(remarkHtml)
    .processSync(page.content)
    .toString()

  return (
    <Layout>
      <PageTemplate
        content={content}
        contentComponent={HTMLContent}
        helmet={<Helmet title={`${page.title} | ${edition.name}`} />}
        title={page.title}
        featuredImage={page.featuredImage}
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }),
}

export default Page

export const pageQuery = graphql`
  query PageByID($id: String!, $currentEditionId: Int!) {
    page: fluxiblePages(id: { eq: $id }) {
      id
      title
      content
      featuredImage {
        name
        altText
        contentType
      }
    }
    edition: fluxibleEditions(alternative_id: { eq: $currentEditionId }) {
      name
      startDate
      endDate
    }
  }
`
